<template>
  <Order />
</template>

<script>
import Order from '@/components/Order.vue';

export default {
  name: 'App',
  components: {
    Order,
  },
  data() {
    return {
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprotor:api',
        mode: 'class',
      },
    };
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);
  },
};
</script>

<style lang="scss">
  #order-app {
    max-width: 100%;

    @media (max-width: 575px) {
      padding: 22px;
    }
  }
</style>
