<template>
  <div>
    <h2 class="scs-title">Оформление</h2>

    <p class="scs-text">
      Вам необходимо ознакомиться с
      <a href="/include/rules/rules.zip" target="_blank">правилами посещения</a>
    </p>

    <template v-if="linkPay">
      <p class="text-center">
        Заказ успешно создан!

        <a :href="linkPay" class="scs-btn scs-btn--small mt-3" target="_blank">Оплатить</a>
      </p>
    </template>

    <template v-if="!linkPay && basketStorage.length > 0">
      <form @submit.prevent="(privacyPolicy) ? submit() : false">
        <div
          v-if="errorMessage"
          class="form__message form__message--error"
          v-html="errorMessage"
        ></div>

        <div class="scs-order">
          <div class="row mt-5">
            <template v-for="(item, index) in props">
              <div class="col-lg-4" :key="index">
                <Input
                  v-model="item.value"
                  :name="item.name"
                  :type="item.type"
                  :placeholder="item.placeholder"
                  :required="item.required"
                  :mask="item.mask"
                  :rule="item.rule"
                  :errorText="item.errorText"
                  @input="(item.onInput) ? item.onInput($event, index) : ''"
                />
              </div>
            </template>
          </div>

          <template v-if="!sendCodeCompleted">
            <div class="row justify-content-end justify-content-lg-between mt-2">
              <div class="col-sm-5 col-md-4 col-lg-3 text-right">
                <p class="scs-text text-right mb-2">
                  <a
                    href="#"
                    :class="(!checkMail) ? 'scs-disabled' : ''"
                    v-if="!btnCheckMailLoading"
                    @click.prevent="sendCode()"
                  >Подтвердить e-mail</a>
                </p>

                <Preloader v-if="btnCheckMailLoading"/>

                <div
                  class="scs-text"
                  v-if="errorMessageCheckEmail"
                  v-html="errorMessageCheckEmail"
                ></div>
              </div>

              <div class="col-12 col-lg-auto order-lg-first">
                <span style="color: red">*</span> - поля обязательные поля для заполнения
              </div>
            </div>
          </template>

          <template v-if="sendCodeCompleted && !checkCodeCompleted">
            <div class="row mt-3">
              <div class="col-12 mb-3">
                Код для подтверждения отправлен на почту
              </div>

              <div class="col-lg-4">
                <Input
                  v-model="code"
                  type="text"
                  placeholder="Код подтверждения"
                  :required="true"
                />
              </div>

              <div class="col-sm-5 col-md-4 col-lg-3 align-self-end">
                <a
                  href="#"
                  class="scs-btn mb-2"
                  :class="(!code) ? 'scs-btn--disabled' : ''"
                  v-if="!btnCheckMailLoading"
                  @click.prevent="checkCode()"
                >Подтвердить</a>

                <Preloader v-if="btnCheckMailLoading"/>
              </div>

              <div
                v-if="errorMessageCheckEmail"
                class="col-12"
                v-html="errorMessageCheckEmail"
              ></div>
            </div>
          </template>

          <template v-if="checkCodeCompleted">
            <div>E-mail подтвержден</div>
          </template>

          <p class="mt-3" v-if="sendCodeCompleted">
            <span style="color: red">*</span> - поля обязательные поля для заполнения
          </p>

          <tenplate v-if="checkCodeCompleted">
            <div class="row mt-4">
              <div class="col-lg-auto">
                <p>Способ оплаты:</p>
              </div>

              <div class="col-lg-auto">
                <div
                  class="delivery-item"
                  :class="(activePay) ? 'delivery-item--active' : ''"
                  @click.once="activePay = true"
                >
                  <div
                    class="delivery-item__img"
                    :class="(activePay) ? 'delivery-item__img--active' : ''"
                  ></div>
                </div>
              </div>
            </div>

            <div class="scs-bottom-text mt-4">
              <label class="scs-checkbox">
                <input
                  type="checkbox"
                  v-model="useCuponBirthday"
                />

                <span>
                  <div>
                    Подтверждаю право на использование скидки именинника, с правилами и условиями
                    предоставления скидки ознакомлен, согласен с  обязанностью предоставлением
                    документа сотруднику центра при активации билета
                  </div>
                </span>
              </label>
            </div>

            <div class="scs-bottom-text mt-4">
              <label class="scs-checkbox">
                <input
                  type="checkbox"
                  v-model="privacyPolicy"
                />

                <span>
                  <div>
                    Нажимая, вы даёте разрешение на
                    <a href="#personal" data-fancybox class="popup">
                      обработку персональных данных
                    </a>
                    и соглашаетесь с
                    <a href="/include/rules/rules.pdf" target="_blank">
                      правилами посещения центра
                    </a>
                  </div>
                </span>
              </label>
            </div>

            <div class="row mt-3">
              <div class="col-sm-5 col-md-4 col-lg-3">
                <input
                  type="submit"
                  class="scs-btn scs-btn--big"
                  :class="(!checkForm
                     || !privacyPolicy || !useCuponBirthday) ? 'scs-btn--disabled' : ''"
                  value="Оформить"
                  v-if="!btnLoading"
                />

                <Preloader v-if="btnLoading"/>
              </div>
            </div>
          </tenplate>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Input from '@/components/Input.vue';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'Order',
  components: {
    Input,
    Preloader,
  },
  data() {
    return {
      errorMessage: '',
      btnLoading: false,
      basketStorage: [],
      linkPay: '',
      privacyPolicy: false,
      useCuponBirthday: true,
      btnCheckMailLoading: false,
      checkMail: false,
      sendCodeCompleted: false,
      checkCodeCompleted: false,
      errorMessageCheckEmail: '',
      code: '',
      codeId: null,
      activePay: true,
      props: [
        {
          name: 'PHONE',
          type: 'text',
          placeholder: 'Номер телефона',
          required: true,
          value: '',
          mask: '+9 (999) 999-99-99',
          rule: /^\+[7] \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/,
          onInput: (e, index) => this.autoCorrection(e, index),
          errorText: 'Неправильный формат',
        },
        {
          name: 'NAME',
          type: 'text',
          placeholder: 'Ваше имя',
          required: true,
          value: '',
          mask: '',
        },
        {
          name: 'EMAIL',
          type: 'text',
          placeholder: 'Ваш E-mail',
          required: true,
          value: '',
          mask: '',
          rule: /^.+@.+\..{2,}$/,
          onInput: (e, index) => this.checkingMail(e, index),
        },
      ],
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    checkForm() {
      const checkFields = this.props.every((item) => {
        if (item.name === 'PHONE' && item.value.indexOf('_') > -1) {
          return false;
        }

        if (item.rule && !item.rule.test(item.value)) {
          return false;
        }

        if (item.required && (item.value.length > 0 || item.value)) {
          return true;
        }

        if (!item.required) {
          return true;
        }

        return false;
      });

      return checkFields && this.privacyPolicy && this.checkCodeCompleted && this.useCuponBirthday;
    },
  },
  methods: {
    autoCorrection(val, index) {
      this.props[index].value = (val[1] === '8') ? '+7 (' : val;
      console.log(val);
    },
    checkingMail(val, index) {
      this.code = '';
      this.sendCodeCompleted = false;
      this.checkCodeCompleted = false;
      const item = this.props[index];
      this.checkMail = item.rule && item.rule.test(val);
    },
    sendCode() {
      if (this.checkMail) {
        this.errorMessageCheckEmail = '';
        this.btnCheckMailLoading = true;
        this.sendCodeCompleted = false;
        this.codeId = null;

        const item = this.props.find((elem) => elem.name === 'EMAIL');
        console.log(item);
        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'sendEmailCode');

        const params = new FormData();

        params.append('email', item.value);

        axios
          .post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const { data } = response.data;

            if (!data.success) {
              this.errorMessageCheckEmail = data.message;
            } else {
              this.sendCodeCompleted = true;
              this.codeId = data.id;
            }

            this.btnCheckMailLoading = false;
          })
          .catch((error) => {
            this.errorMessageCheckEmail = 'Ошибка!';
            this.btnCheckMailLoading = false;
            console.log(error);
          });
      }
    },
    checkCode() {
      if (this.code) {
        this.errorMessageCheckEmail = '';
        this.btnCheckMailLoading = true;
        this.checkCodeCompleted = false;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'checkEmailCode');

        const params = new FormData();

        params.append('id', this.codeId);
        params.append('code', this.code);

        axios
          .post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const { data } = response.data;

            if (!data.success) {
              this.errorMessageCheckEmail = data.message;
            } else {
              this.checkCodeCompleted = true;
            }

            this.btnCheckMailLoading = false;
          })
          .catch((error) => {
            this.errorMessageCheckEmail = 'Ошибка!';
            this.btnCheckMailLoading = false;
            console.log(error);
          });
      }
    },
    submit() {
      this.errorMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'addOrder');

        // const params = new URLSearchParams();
        const params = new FormData();

        this.props.forEach((item) => {
          params.append(item.name, item.value);
        });

        params.append('arItems', JSON.stringify(this.basketStorage));

        const coupon = localStorage.getItem('coupon') || '';
        params.append('coupon', coupon);

        axios
          .post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const { data } = response.data;

            if (!data.success) {
              this.errorMessage = data.message;
            } else {
              localStorage.setItem('basket', JSON.stringify([]));
              window.dispatchEvent(new CustomEvent('updateBasket', {
                detail: JSON.parse(localStorage.getItem('basket')),
              }));

              this.linkPay = data.link;
            }

            this.btnLoading = false;
          })
          .catch((error) => {
            this.errorMessage = 'Ошибка!';
            this.btnLoading = false;
            console.log(error);
          });
      } else {
        this.errorMessage = 'Заполните обязательные поля';
      }
    },
    getBasketStorage() {
      this.basketStorage = (JSON.parse(localStorage.getItem('basket'))) || [];
    },
    onStorageUpdate(event) {
      this.basketStorage = (event.detail) || [];
      this.linkPay = '';
    },
  },
  created() {
    window.addEventListener('updateBasket', this.onStorageUpdate);

    this.getBasketStorage();
  },
};
</script>

<style lang="scss" scoped>
  .scs-title {
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
    text-align: center;
    color: #0DB2A2;

    @media (max-width: 575px) {
      font-size: 36px;
      line-height: 50px;
    }
  }

  .scs-subtitle {
    font-size: 24px;
    line-height: 75px;

    @media (max-width: 575px) {
      font-size: 20px;
      line-height: 50px;
    }
  }

  .scs-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    a {
      color: #0DB2A2;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 757px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .scs-text-bottom {
    font-size: 15px;
    line-height: 26px;
    text-align: right;

    a {
      color: #0DB2A2;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 575px) {
      text-align: center;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .scs-order {
    max-width: 100%;
    width: 1200px;
    font-size: 18px;
    line-height: 26px;
  }

  .delivery-item {
    max-width: 330px;
    width: 330px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(204, 221, 219, 0.2);
    border-radius: 10px;
    padding: 3px 15px;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    border: 2px solid #CACACA;
    cursor: pointer;
    transition: border-color .25s ease-in-out;

    &__img {
      background-image: url(~@/assets/img/pay3.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      opacity: .5;
      transition: opacity .25s ease-in-out;

      &--active {
        opacity: 1;
      }
    }

    &:hover,
    &--active {
      border-color: #0db2a17c;
    }
  }

  .scs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #F39712;
    border-radius: 7px;
    width: 100%;
    height: 52px;
    padding: 0 5px;
    border: 0;
    outline: none;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &:hover {
      background: #f1b358;
    }

    &--small {
      max-width: 220px;
      margin: 0 auto;
    }

    &--big {
      font-size: 17px;
      line-height: 19px;
      height: 70px;
    }

    &--disabled {
      background: #ccc;

      &:hover {
        background: #ccc;
      }
    }
  }

  .scs-checkbox {

    input {
      display: none;

      &:checked + span:before {
        background-image: url(~@/assets/img/check.svg);
      }
    }

    span {
      display: inline-flex;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 15px;
        border: 1px solid #e0e0e0;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        margin-top: 3px;
      }
    }
  }

  .scs-bottom-text {
    font-size: 15px;
    line-height: 26px;

    a {
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #000;
        text-decoration: none;
      }
    }
  }

  .scs-disabled {
    color: #ccc !important;
  }
</style>
