<template>
  <label>
    <div class="scs-title">
      {{ placeholder }}
      <template v-if="required">
        <span>*</span>
      </template>
    </div>

    <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      v-mask="mask"
      @input="updateInput($event.target.value)"
      @keyup.once="noClick = false"
    />

    <template v-if="error && !noClick">
      <span class="scs-error">
        {{ errorText }}
      </span>
    </template>
  </label>
</template>

<script>
export default {
  name: 'Input',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: true,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    mask: {
      type: String,
      default: '',
    },
    rule: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noClick: true,
    };
  },
  computed: {
    error() {
      return this.rule && !this.rule.test(this.value);
    },
  },
  methods: {
    updateInput(val) {
      this.$emit('input', val);
    },
  },
  // mounted() {
  //   if (this.name === 'PHONE') {
  //     window.agCountryMaskLoad(window.cMaskParamsObj, 'JQ Ready');

  //     document.addEventListener('keyup', (e) => {
  //       const target = e.target.closest('input[name="PHONE"]');

  //       if (target) {
  //         this.updateInput(target.value);
  //       }
  //     });

  //     document.addEventListener('click', (e) => {
  //       const target = e.target.closest('input[name="PHONE"]');

  //       if (target) {
  //         this.updateInput(target.value);
  //       }
  //     });

  //     document.addEventListener('paste', (e) => {
  //       const target = e.target.closest('input[name="PHONE"]');

  //       if (target) {
  //         this.updateInput(target.value);
  //       }
  //     });
  //   }
  // },
};
</script>

<style lang="scss" scoped>
  label {
    display: block;

    input {
      border: 1px solid #CACACA;
      border-radius: 5px;
      width: 100%;
      height: 52px;
      padding: 5px 15px;
      background: #fff;
      margin: 0;
      outline: none;
      font-size: 16px;
      line-height: 22px;
    }

    .scs-title {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 15px;

      span {
        color: red;
      }
    }

    .scs-error {
      font-size: 14px;
      color: red;
    }
  }
</style>

<style lang="scss">
  label {
    .ag-countrymask.iti {
      display: block;
    }
  }
</style>
